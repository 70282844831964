<template>
  <div v-if="isSuccess" class="text-center">
    <h4>You have successfully reset your password.</h4>
    <p>
      Please check your email: <b>{{ form.email }}</b>
    </p>
  </div>
  <template v-else>
    <h2 class="text-center mt-0 font-normal">Reset password</h2>
    <form class="block" novalidate @submit.prevent="onSubmit">
      <div class="mb-4 relative">
        <div class="p-float-label p-input-icon-left w-full">
          <i class="pi pi-envelope" />
          <InputText
            id="email"
            type="text"
            v-model="$v.email.$model"
            :class="{ 'p-invalid': $v.email.$errors.length }"
            :disabled="isLoading"
            class="p-inputtext-lg w-full" />
          <label for="email">Email</label>
        </div>
        <p
          class="text-xs text-red-500 m-0 m-1 absolute"
          v-for="error in $v.email.$errors"
          :key="error.$uid">
          {{ error.$message }}
        </p>
      </div>
      <div class="flex align-items-center justify-content-center">
        <Btn
          type="submit"
          class="p-button-lg font-bold"
          label="Send Password Reset Link"
          :loading="isLoading" />
      </div>
    </form>
  </template>
</template>
<script setup>
import InputText from "primevue/inputtext";
import Btn from "primevue/button";
import { reactive, ref } from "vue";

import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useToast } from "primevue/usetoast";
import { resetEmailPasswordRequest } from "@/api/authService";

const $toast = useToast();

const form = reactive({
  email: "",
});

const rules = {
  email: { required, email, $lazy: true },
};

const $v = useVuelidate(rules, form);

let isLoading = ref(false);
let isSuccess = ref(false);

const onSubmit = async () => {
  if (isLoading.value) {
    return;
  }
  $v.value.$touch();
  if ($v.value.$invalid) {
    return;
  }
  try {
    isLoading.value = true;
    const { success = true, message = `Please check your email: ${form.email}` } =
      await resetEmailPasswordRequest(form);
    $toast.add({
      severity: success ? "success" : "warn",
      summary: success ? "Success" : "Error",
      detail: message,
      life: 5000,
    });
    isSuccess.value = true;
  } catch (e) {
    if (e.response && e.response.status === 400) {
      $toast.add({
        severity: "error",
        summary: "Invalid Customer Email",
        detail:
          "No account found for this email. If you believe this is an error, please contact your system administrator.",
        life: 10000,
      });
    }
  } finally {
    isLoading.value = false;
  }
};
</script>
<style scoped></style>
